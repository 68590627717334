input,
select,
textarea,
fieldset {
  font-size: $form-field-font-size;
  margin-top: 0;
  margin-bottom: $space-1;
}

input[type=text],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week] {
  box-sizing: border-box;
  height: $form-field-height;
  padding: $form-field-padding-y $form-field-padding-x;
  vertical-align: middle;
  -webkit-appearance: none;
}

select {
  box-sizing: border-box;
  line-height: 1.75;
  padding: $form-field-padding-y $form-field-padding-x;
}

select:not([multiple]) {
  height: $form-field-height;
  vertical-align: middle;
}

textarea {
  box-sizing: border-box;
  line-height: 1.75;
  padding: $form-field-padding-y $form-field-padding-x;
}

.form-stacked input,
.form-stacked textarea,
.form-stacked select {
  width: 100%;
}

.field-light {
  background-color: white;
  transition: box-shadow .2s ease;
  border-style: solid;
  border-width: $border-width;
  border-color: $border-color;
  border-radius: $border-radius;
}

.field-light:focus {
  outline: none;
  border-color: $blue;
  box-shadow: 0 0 2px rgba($blue,.5);
}

.field-light:disabled {
  color: $mid-gray;
  background-color: $darken-2;
}

.field-light:read-only:not(select) {
  background-color: $darken-2;
}

.field-light:invalid {
  border-color: $red;
}

.field-light.is-success {
  border-color: $green;
}

.field-light.is-warning {
  border-color: $yellow;
}

.field-light.is-error {
  border-color: $red;
}


.radio-light,
.checkbox-light {
  transition: box-shadow .2s ease;
}

.radio-light {
  border-radius: 50%;
}

.radio-light:focus,
.checkbox-light:focus {
  outline: none;
  box-shadow: 0 0 2px rgba($blue, .5);
}
