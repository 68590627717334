@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
// $base-font-family: 'Merriweather', 'PT Serif', Georgia, 'Times New Roman', serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

// $monospace-font-family: 'Source Code Pro', Consolas, monospace !default;

$spacing-unit:     30px !default;

$text-color:       #333 !default;
$background-color: #fdfdfd !default;
$brand-color:      #444 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Whitespace
$space-1: .5rem !default;

// Colors
$blue: #0076df !default;
$red: #f95020 !default;
$green: #00cf26 !default;
$yellow: #efcc00 !default;
$orange: #ffcc22 !default;
$purple: #f92080 !default;
$dark-blue: #00369f !default;
$green: #00ab37 !default;
$dark-green: #009f06 !default;
$light-pink: #ffdddd !default;
$light-green: #ddffdd !default;
$dark-gray: #444 !default;
$mid-gray: #777 !default;
$light-gray: #ccc !default;
$lighter-gray: #eee !default;
$darken-1: rgba(#000,.0625) !default;
$darken-2: rgba(#000,.125) !default;
$darken-3: rgba(#000,.25) !default;
$darken-4: rgba(#000,.5) !default;

// Borders
$border-color: $light-gray !default;
$border-width: 1px !default;
$border-radius: 3px !default;

// Forms
$form-field-font-size: 1rem;
$form-field-height: 2.25rem;
$form-field-padding-y: .5rem;
$form-field-padding-x: .5rem;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "_forms"
;
